import React, { useEffect, useState } from "react";
import { Environment } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { base_remote } from "./ModelsStore";

const envfile = `${base_remote}/snow-bridge-track/environment.hdr`;
THREE.Cache.enabled = true;

function StarsEnvironment({ ...props }) {
  return <Environment background files={envfile} {...props} />;
}

export default StarsEnvironment;
