import React, { useEffect, useMemo, useState } from "react";
import { OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useRef } from "react";
import _ from "lodash";
import { create as createZustand } from "zustand";
import { p_vec3 } from "../utils/vectorutils.js";
import { getv, nils } from "../utils/utils.js";

export const useCameraControls = createZustand((set) => ({
  loaded: false,
  update: (upd = {}) => {
    return set((state) => ({ ...state, ...upd }));
  },
}));

function CameraControls() {
  const r3fs = useThree();
  const camct = useCameraControls();

  const camera_ref = useRef(null);
  const orbit_ref = useRef(null);
  const target_ref = useRef(null);
  const dcamct_ref = useRef(null);

  useFrame((s) => {
    let target = target_ref.current;
    let camera = s.camera;
    if (!target || !camera) return;
  });

  const cam_init = () => {
    camct.update({
      loaded: true,
      tarobj: target_ref.current,
      orbit: orbit_ref.current,
      camera: orbit_ref.current.object,
    });
  };

  useEffect(() => {
    let orbit = orbit_ref.current;
    let tarobj = target_ref.current;
    let camera = orbit_ref.current?.object;
    if (!tarobj || !camera || !orbit) return;
    cam_init();
  }, [orbit_ref.current, target_ref.current]);

  const cam_restrictions = {
    minDistance: 3.5,
    maxDistance: 60,

    minPolarAngle: Math.PI * 0,
    maxPolarAngle: Math.PI / 2 - 0.1,

    near: 0.1,
    far: 3000,

    enablePan: false,
  };

  useFrame(() => {
    let camera = orbit_ref.current?.object;
    let tar = orbit_ref.current?.target;
    if (!camera) return;
    // console.log(p_vec3(camera.position), p_vec3(tar));
  });

  // useFrame(() => {
  //   let orbit = orbit_ref.current;
  //   let tarobj = target_ref.current;
  //   let camera = orbit_ref.current?.object;
  //   if (!tarobj || !camera || !orbit) return;

  //   orbit.target.copy(tarobj.position);
  //   orbit.update();
  // });

  return (
    <>
      <OrbitControls
        enableDamping={false}
        ref={orbit_ref}
        // target={target_ref?.current?.position}
        {...cam_restrictions}
      />

      <mesh ref={target_ref} scale={0.02}>
        <boxGeometry />
        <meshBasicMaterial color={"#ffffff"} transparent={true} opacity={0} />
      </mesh>
    </>
  );
}

export default CameraControls;
